<template>
    <div class="container">
    <div class="col-md-12">
        <div class="offer-dedicated-body-left">
            <div class="tab-content" id="pills-tabContent">
                <div class="tab-pane fade active show" id="pills-reviews" role="tabpanel" aria-labelledby="pills-reviews-tab">
                    <div class="bg-white rounded shadow-sm p-4 mb-4 restaurant-detailed-ratings-and-reviews">
                        <h5 class="mb-1">{{ $t('ratings_and_reviews.all_ratings_and_reviews') }}</h5>
                        <div class="reviews-members pt-4 pb-4" v-for="(ratingVal, index) in allRatings" :key="index">
                            <div class="media" v-if="currentUserID !== ratingVal.user.id">
                                <div class="review-icon">
                                    <a href="#"><img alt="Generic placeholder image" src="http://bootdey.com/img/Content/avatar/avatar1.png" class="mr-3 rounded-pill"></a>
                                </div>
                                <div class="media-body">
                                    <div class="reviews-members-header">
                                        <span class="star-rating float-right">
                                        <a href="#"><i class="icofont-ui-rating active"></i></a>
                                        <a href="#"><i class="icofont-ui-rating active"></i></a>
                                        <a href="#"><i class="icofont-ui-rating active"></i></a>
                                        <a href="#"><i class="icofont-ui-rating active"></i></a>
                                        <a href="#"><i class="icofont-ui-rating"></i></a>
                                        </span>
                                     <h6 class="mb-1"><a class="text-black" href="#">{{ ratingVal.user.name }}</a></h6>
                                     <p class="text-gray">{{ ratingVal.created_at }}</p>
                                    </div>
                                    <div class="rating">
                                        <div class="stars">
                                            <span class="fa fa-star" :class="{ 'checked': ratingVal.rating >= 1 }" ></span>
                                            <span class="fa fa-star" :class="{ 'checked': ratingVal.rating >= 2 }" ></span>
                                            <span class="fa fa-star" :class="{ 'checked': ratingVal.rating >= 3 }" ></span>
                                            <span class="fa fa-star" :class="{ 'checked': ratingVal.rating >= 4 }" ></span>
                                            <span class="fa fa-star" :class="{ 'checked': ratingVal.rating >= 5 }" ></span>
                                        </div>
                                    </div>
                                    <div class="reviews-members-body">
                                    <p>{{ ratingVal.review }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>
<script>
    import API from '@/api'
    export default {
        name: 'propertyDetail',
        components: {
        },
        data(){
            return {
                allRatings: {},
                ratingVal: {},
                rating: 0, 
                reviews: 0,
                currentUserRating: '',
                currentUserReview: '',
                currentUserID:'',
                userReview:''
            }
        },
        mounted() {
          this.getRatingAndReviews();
        },
        methods: {
       
            getRatingAndReviews(){
                const propertyId = this.$route.params.id;
                console.log(propertyId);
                API.getRatingAndReviews(propertyId, res =>  {
                    this.allRatings = res.data;
                    this.currentUserID = res.userID;
                    res.data.forEach((value, key)=> {
                        value.created_at = this.formatDate(value.created_at);
                        if(res.userID === value.user.id){
                            this.currentUserRating = value.rating;
                            this.currentUserReview = value.review;
                            this.rating = value.rating;
                            this.userReview = value.review;
                        }
                    });
                }, err => {
                    console.log(err)
                })
            },
            formatDate(dateString) {
                const date = new Date(dateString);
                const month = (date.getMonth() + 1).toString().padStart(2, '0'); 
                const day = date.getDate().toString().padStart(2, '0');
                const year = date.getFullYear();

                return `${month}/${day}/${year}`;
            },
           
        },
        created() {
           
        },
    }
</script>

<style scoped>
body{
background:#dcdcdc;    
}
.total-like-user-main a {
    display: inline-block;
    margin: 0 -17px 0 0;
}
.total-like {
    border: 1px solid;
    border-radius: 50px;
    display: inline-block;
    font-weight: 500;
    height: 34px;
    line-height: 33px;
    padding: 0 13px;
    vertical-align: top;
}
.restaurant-detailed-ratings-and-reviews hr {
    margin: 0 -24px;
}
.graph-star-rating-header .star-rating {
    font-size: 17px;
}
.progress {
    background: #f2f4f8 none repeat scroll 0 0;
    border-radius: 0;
    height: 30px;
}
.checked, .price span {
  color: #ff9f1a; }
.rating-list {
    display: inline-flex;
    margin-bottom: 15px;
    width: 100%;
}
.rating-list-left {
    height: 16px;
    line-height: 29px;
    width: 10%;
}
.rating-list-center {
    width: 80%;
}
.rating-list-right {
    line-height: 29px;
    text-align: right;
    width: 10%;
}
.restaurant-slider-pics {
    bottom: 0;
    font-size: 12px;
    left: 0;
    z-index: 999;
    padding: 0 10px;
}
.restaurant-slider-view-all {
    bottom: 15px;
    right: 15px;
    z-index: 999;
}
.offer-dedicated-nav .nav-link.active,
.offer-dedicated-nav .nav-link:hover,
.offer-dedicated-nav .nav-link:focus {
    border-color: #3868fb;
    color: #3868fb;
}
.offer-dedicated-nav .nav-link {
    border-bottom: 2px solid #fff;
    color: #000000;
    padding: 16px 0;
    font-weight: 600;
}
.offer-dedicated-nav .nav-item {
    margin: 0 37px 0 0;
}
.restaurant-detailed-action-btn {
    margin-top: 12px;
}
.restaurant-detailed-header-right .btn-success {
    border-radius: 3px;
    height: 45px;
    margin: -18px 0 18px;
    min-width: 130px;
    padding: 7px;
}
.text-black {
    color: #000000;
}
.icon-overlap {
    bottom: -23px;
    font-size: 74px;
    opacity: 0.23;
    position: absolute;
    right: -32px;
}
.menu-list img {
    width: 41px;
    height: 41px;
    object-fit: cover;
}
.restaurant-detailed-header-left img {
    width: 88px;
    height: 88px;
    border-radius: 3px;
    object-fit: cover;
    box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075)!important;
}
.reviews-members .media .mr-3 {
    width: 56px;
    height: 56px;
    object-fit: cover;
}
.rounded-pill {
    border-radius: 50rem!important;
}
.total-like-user {
    border: 2px solid #fff;
    height: 34px;
    box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075)!important;
    width: 34px;
}
.total-like-user-main a {
    display: inline-block;
    margin: 0 -17px 0 0;
}
.total-like {
    border: 1px solid;
    border-radius: 50px;
    display: inline-block;
    font-weight: 500;
    height: 34px;
    line-height: 33px;
    padding: 0 13px;
    vertical-align: top;
}
.restaurant-detailed-ratings-and-reviews hr {
    margin: 0 -24px;
}
.graph-star-rating-header .star-rating {
    font-size: 17px;
}
.progress {
    background: #f2f4f8 none repeat scroll 0 0;
    border-radius: 0;
    height: 30px;
}
.rating-list {
    display: inline-flex;
    margin-bottom: 15px;
    width: 100%;
}
.rating-list-left {
    height: 16px;
    line-height: 29px;
    width: 10%;
}
.rating-list-center {
    width: 80%;
}
.rating-list-right {
    line-height: 29px;
    text-align: right;
    width: 10%;
}
.restaurant-slider-pics {
    bottom: 0;
    font-size: 12px;
    left: 0;
    z-index: 999;
    padding: 0 10px;
}
.restaurant-slider-view-all {
    bottom: 15px;
    right: 15px;
    z-index: 999;
}

.progress {
    background: #f2f4f8 none repeat scroll 0 0;
    border-radius: 0;
    height: 30px;
}


</style>